.App {
  text-align: center;
  background-color: rgb(241, 241, 241) !important;
}

.App-logo {
  height: 40vmin;
}

.collect-info {
    color: black;
}

.container-max {
  margin: 0 auto;
  max-width: 960px;
}

.grid-bottom {
  display: grid;
  grid-template-columns: auto auto auto;
}

.item1 {
  grid-column-start: 1;
  grid-column-end: 2;
}

.buttons {
  grid-column-start: 2;
  grid-column-end: 3;
  display: inline-flex;
  margin: 0 auto;
}

th {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-bottom: 100px;
}

.icon {
  margin: 10px;
  color: #F05F57;
  filter: invert(47%) sepia(40%) saturate(2192%) hue-rotate(329deg) brightness(102%) contrast(88%);
}

.hint {
  color: #F05F57;
  font-family: Calibri, Georgia, Times, 'Times New Roman', serif;
}

.tableDiv {
  padding: 10%;
  width: 80%;
}

@media only screen and (max-width: 600px) {
  .tableDiv {
    padding: 5%;
    width: 85%;
  }
  
  th {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .item1 {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  
  .btn1 {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  
  .rc-time-picker-clear {
    display: none;
  }

}

.rc-time-picker-clear-icon::after {
  color: #fff !important;
}

.p-20 {
  margin-left: 20px;
}

.float-right {
  float: right;
  display: flex;
}

.bottom-bar {
  padding: 20px;
  background: rgb(88,3,3);
  background-color: #8BC6EC;
  background-image: linear-gradient( 135deg, #F05F57 10%, #360940 100%);
  /* background: linear-gradient(132deg, #730998 0%, #F98585 97%); */
  /* background: linear-gradient(132deg, rgba(88,3,3,1) 0%, rgba(181,29,120,1) 90%); */
  /* background-color: #1e1b18; */
  width: 100%;
  bottom: 0;
  position: fixed;
  -webkit-box-shadow: 5px 6px 27px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 6px 27px 0px rgba(0,0,0,0.75);
  box-shadow: 5px 6px 27px 0px rgba(0,0,0,0.75);
}

.table-dark th, .table-dark thead th {
  border-bottom: 1px solid rgb(255, 255, 255) !important;
  border-top: 0px !important;
}

td {
  border-top: 1px solid rgb(255, 255, 255) !important;
}

#myTable {
  color: white;
  background: rgb(88,3,3);
  background-image: linear-gradient( 135deg, #F05F57 10%, #360940 100%);
  /* background: linear-gradient(132deg, #730998 0%, #F98585 97%); */
  border-radius: 10px;
  -webkit-box-shadow: 5px 6px 27px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 5px 6px 27px 0px rgba(0,0,0,0.35);
  box-shadow: 5px 6px 27px 0px rgba(0,0,0,0.35);
}

.tableToPrint {
  padding: 20px;
  margin: 20px;
  width: 80%;
  margin: 0 auto;
}

.tableToPrint td{
  padding: 20px;
  margin: 0 auto;
}

.tableToPrint thead {
  font-weight: bold;
  font-size: 22px;
  margin: 20px;
  width: 80%;
  margin: 0 auto;
}


.tableToPrint tr:nth-child(2n) {
  color: rgb(92, 92, 92);
}


.print {
	display: none;
}
@media print {
	.no-print  {
		display: none;
	}
	.print  {
		display: block;
  }
  
  .tableToPrint {
    padding: 10%;
    width: 80%;
    margin: 0 auto;
  }
  
  .tableToPrint tr:nth-child(2n) {
    color: rgb(92, 92, 92);
  }
}


.totalRowForPrint {
  margin: 0 auto;
  padding: 20px;
  width: 50%;
  font-size: 22px;
}

.totalRowForPrint p {
  font-size: 22px;
}

.btn-success {
  color: #fff;
  background-color: #d8315b00 !important;
  border-color: #ffffff !important;
}

.btn-success:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.397) !important;
  border-color: #fff !important;
}

.white {
  color: white;
}

.App-link {
  color: #09d3ac;
}

.rc-time-picker-panel-inner {
  border: 1px solid #fff !important;
}

.rc-time-picker-input {
  color: #fff !important;
  background-color: #ffffff54 !important;
  border: 1px solid #d9d9d900 !important;
}





